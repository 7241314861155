<template>
  <div class="studentLearning">
    <div class="top-box">
      <div class="check-box">
        <p class="check-box-title">
          <!-- <template v-if="userMsg && userMsg.roleId != 4">
            {{ optionYearVal | setName(optionYear) }}
            级
            {{ optionsClassVal | setName(optionsClass) }}
            班学生学情
          </template> -->
          <el-select
            v-model="subjectOptionsVal"
            placeholder="请选择"
            @change="changeOption"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </p>
        <div class="check-box-right">
          <el-select
            v-model="optionsTimeVal"
            placeholder="请选择"
            @change="changeOption"
          >
            <el-option
              v-for="item in optionsTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!--    v-if="userMsg.roleId == 4" -->

          <template v-if="userMsg && userMsg.roleId != 4">
            <el-select
              v-model="optionYearVal"
              placeholder="请选择"
              @change="getClass"
            >
              <el-option
                v-for="(item, i) in optionYear"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="optionsClassVal"
              placeholder="请选择班级"
              @change="subjectlistbygradeandclassnum"
            >
              <el-option
                v-for="(item, i) in optionsClass"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="studentVal"
              :disabled="selLoading"
              placeholder="请选择学生"
              filterable
              @change="changeOption"
            >
              <el-option
                v-for="(item, i) in studentList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <el-select
            v-model="examPaperIds"
            multiple
            collapse-tags
            placeholder="全部考试"
            class="set-input morelist"
            @change="setComType()"
          >
            <!-- <el-option label="所有考试" value=""> </el-option> -->
            <el-option
              v-for="item in examList"
              :key="item.examPaperId"
              :label="item.examName"
              :value="item.examPaperId"
            >
            </el-option>
          </el-select>
          <!-- <el-button type="primary" class="btn-all">导出</el-button> -->
        </div>
      </div>
      <information
        v-if="comBoxType"
        :child-obj="childObj"
        :option-year="optionYear"
      ></information>
    </div>
    <knowTable
      v-if="comBoxType"
      :child-obj="childObj"
      :subject-id="subjectId"
      @setId="setId"
    ></knowTable>
    <wrongBox
      v-if="comBoxType"
      :child-obj="childObj"
      :subject-id="subjectId"
      :exam-list="examList"
      :option-year="optionYear"
      :exam-paper-ids="examPaperIds"
      @setId="setId"
      @setPaperIds="setPaperIds"
    ></wrongBox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  optionsTime,
  subjectOptions,
  examTypeOptions,
} from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
import {
  getGradeList,
  classlistbygrade,
} from "@/core/api/school/schoolgradeclass";
import { studentcontact } from "@/core/api/school/schoolstudent";
import { studentsummary } from "@/core/api/analy/gradeLearning";
import wrongBox from "@/components/learning/wrongBox.vue";
import knowTable from "@/components/learning/knowTable.vue";
import information from "@/components/learning/topMsg.vue";
import { subjectlistbygradeandclassnum } from "@/core/api/school/school";
import { getExamListByTimeRange } from "@/core/api/resource/learningPaper";
export default {
  name: "StudentLearning",
  components: {
    wrongBox,
    knowTable,
    information,
  },
  data() {
    return {
      subjectOptions: [],
      oldSubjectOptions: subjectOptions,
      examTypeOptions: examTypeOptions,
      subjectOptionsVal: "",
      childObj: {},
      comBoxType: false,
      optionYearVal: "",
      optionYear: [],
      optionsTimeVal: 0,
      optionsTime: optionsTime,
      optionsClassVal: "",
      optionsClass: [],
      percentage: 50,
      colors: [
        { color: "#2474ED", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#2196F3", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#f56c6c", percentage: 100 },
      ],
      checked: false,
      menuList: [
        { label: "按时间", isClick: true },
        { label: "按章节", isClick: false },
      ],
      value: "",
      checkList: ["1", "2"],
      studentList: [],
      studentVal: "",
      studentData: {},
      titleArr: [],
      listArr: [],
      userMsg: {},
      subjectId: 0,
      loadingType: false,
      selLoading: false,
      examList: [],
      examPaperIds: [],
    };
  },
  computed: {
    ...mapGetters(["getHeaderType"]),
    // ...mapState(["keepArr"])
  },
  watch: {
    getHeaderType: {
      deep: true,
      handler() {
        if (this.loadingType) {
          return;
        }
        this.loadingType = true;
        this.userMsg = getStore({ name: "user-message" });
        // console.log("11111111");
        if (
          this.userMsg &&
          this.userInfo.authorities[0].authority == "ROLE_4"
        ) {
          this.setOption();
          this.studentOn();
        }
        // if (this.userMsg && this.userMsg.roleId != 4) {
        //   // this.subjectOptionsVal = this.subjectOptions[0].value;
        //   this.getOption();
        // }
      },
    },
  },
  created() {
    if (this.loadingType) {
      return;
    }
    // this.loadingType = true;
    this.userMsg = getStore({ name: "user-message" });
    this.userInfo = getStore({ name: "userInfo" });
    // console.log(this.userInfo);
    if (this.userMsg && this.userInfo.authorities[0].authority == "ROLE_4") {
      if (this.userMsg.userSig) {
        this.setOption();
        this.studentOn();
      }
    }
    if (this.userMsg && this.userInfo.authorities[0].authority != "ROLE_4") {
      // this.subjectOptionsVal = this.subjectOptions[0].value;
      this.getOption();
    }
  },
  methods: {
    setPaperIds(val) {
      // console.log(val);
      this.examPaperIds = val;
      this.setComType();
    },
    getExamListByTimeRange() {
      let data = {
        subjectId: this.subjectOptionsVal,
        timeRange: this.optionsTimeVal,
        // examType: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,99"
      };
      if (this.userMsg && this.userMsg.roleId == 4) {
        // this.studentOn();
        data.studentId = this.userMsg.studentId;
        data.levelId = this.userMsg.levelId;
        data.classNums = this.userMsg.classNum;
        data.gradeId = this.userMsg.gradeId;
      } else {
        data.studentId = this.studentVal;
        data.levelId = this.optionYear.filter(
          (i) => i.value == this.optionYearVal
        )[0].level;
        data.gradeId = this.optionYearVal;
        data.classNums = this.optionsClassVal;
      }

      this.examList = [];
      this.examPaperIds = [];
      getExamListByTimeRange(data).then((res) => {
        // console.log(res);
        this.examList = res.data.data;
        let paperIds = [];
        this.examList.map((index) => {
          paperIds.push(index.examPaperId);
          let str = this.examTypeOptions.filter(
            (item) => index.type == item.value
          )[0].label;
          index.examName =
            index.examDate + " " + index.examName + "（" + str + "）";
        });
        this.setComType(paperIds.join(","));
      });
    },
    // 根据年级 班级获取科目列表
    subjectlistbygradeandclassnum() {
      this.subjectOptions = [];
      let data = {
        gradeId: this.optionYearVal,
        classNum: this.optionsClassVal,
      };
      this.studentVal = "";
      this.studentList = [];

      subjectlistbygradeandclassnum(data).then((res) => {
        if (res.data.data.length == 0) {
          return;
        }
        let isTrue =
          res.data.data.filter((item) => item.id == this.subjectOptionsVal)
            .length == 0;
        if (isTrue) {
          if (res.data.data.filter((item) => item.id == 2).length == 1) {
            this.subjectOptionsVal = 2;
          } else {
            this.subjectOptionsVal = res.data.data[0].id;
          }
        }
        res.data.data.forEach((index) => {
          this.subjectOptions.push({
            value: index.id,
            label: index.name,
          });
        });
        if (this.optionsClassVal == "重点关注") {
          this.setStudent();
        } else {
          this.getStudent();
        }
      });
    },
    setStudent() {
      this.studentList = [];
      this.studentData.students.forEach((index) => {
        this.studentList.push({
          value: index.id,
          label: index.name,
        });
      });
      if (this.studentList.length > 0) {
        this.studentVal = this.studentList[0].value;
      }
      this.getExamListByTimeRange();
    },
    setId(id) {
      this.subjectId = id;
    },
    studentOn() {
      //  subjectId: this.subjectOptionsVal,
      this.childObj.studentId = this.userMsg.studentId;
      this.childObj.username = this.userMsg.name;
      this.childObj.subjectId = this.subjectOptionsVal;
      this.childObj.timeRange = this.optionsTimeVal;
      this.childObj.gradeId = this.userMsg.gradeId;
      this.childObj.classNum = this.userMsg.classNum;
      this.childObj.level = this.userMsg.levelId;
      this.childObj.examPaperIds = this.examPaperIds.join(",");
      this.comBoxType = true;
      this.childObj.studentType = true;
      if (this.childObj.studentId) {
        this.loadingType = true;
      }

      this.getExamListByTimeRange();
      this.getStudentMsg();
    },
    setOption() {
      // oldSubjectOptions
      let subjectList = [...this.oldSubjectOptions];
      // console.log(subjectList);
      // subjectList.splice(4, 0, { value: 11, label: "道德与法治" });
      if (this.userMsg.levelId == 2) {
        this.subjectOptions = subjectList.filter((item) => item.value != 6);
      } else {
        this.subjectOptions = subjectList.filter((item) => item.value != 11);
      }
      this.subjectOptionsVal = this.subjectOptions[0].value;
    },
    clickStudent() {
      // this.studentList.map(index => {
      //   index.on = false;
      //   if (v.id == index.id) {
      //     index.on = true;
      //   }
      // });
      // this.studentList = JSON.parse(JSON.stringify(this.studentList));
      this.setComType();
      this.getStudentMsg();
    },
    getStudentMsg() {
      studentsummary(this.childObj).then((res) => {
        if (res.data.data) {
          this.studentData = res.data.data;
          this.studentData.classRate = Number(
            (this.studentData.classRate * 100).toFixed(2)
          );
          this.studentData.gradeRate = Number(
            (this.studentData.gradeRate * 100).toFixed(2)
          );

          this.titleArr = [];
          this.listArr = [{ 科目: "班级前" }, { 科目: "年级前" }];
          this.titleArr.push({ name: "科目" });
          this.studentData.subjectSummaryList.forEach((index) => {
            this.titleArr.push({ name: index.subjectName });
            this.listArr[0][index.subjectName] = Number(
              (index.classRate * 100).toFixed(2)
            );
            this.listArr[1][index.subjectName] = Number(
              (index.gradeRate * 100).toFixed(2)
            );
          });
        }
      });
    },
    changeOption() {
      this.getExamListByTimeRange();
    },
    setComType(paperIds) {
      this.childObj = {
        subjectId: this.subjectOptionsVal,
        timeRange: this.optionsTimeVal,
        gradeId: this.optionYearVal,
        classNum: this.optionsClassVal,
        paperIds: paperIds,
        examPaperIds: paperIds ? paperIds : this.examPaperIds.join(","),
        // studentId: this.studentList.filter(item => item.on)[0].id,
        // studentId: this.studentVal,
        // level: this.optionYear.filter(i => i.value == this.optionYearVal)[0]
        //   .level
        // studentId: 1793
      };
      if (this.userMsg && this.userMsg.roleId == 4) {
        // this.studentOn();
        this.childObj.studentId = this.userMsg.studentId;
        this.childObj.username = this.userMsg.name;
        this.childObj.level = this.userMsg.levelId;
        this.childObj.gradeId = this.userMsg.gradeId;
        this.childObj.classNum = this.userMsg.classNum;
      } else {
        this.childObj.studentId = this.studentVal;
        this.childObj.username = this.studentList.filter(
          (item) => item.value == this.studentVal
        )[0].label;
        this.childObj.level = this.optionYear.filter(
          (i) => i.value == this.optionYearVal
        )[0].level;
      }
      this.comBoxType = true;
      this.getStudentMsg();
    },
    getStudent() {
      let data = {
        classNum: this.optionsClassVal,
        // current: 1,
        gradeId: this.optionYearVal,
        // size: 100,
      };
      this.studentVal = "";
      this.studentList = [];
      this.selLoading = true;
      studentcontact(data)
        .then((res) => {
          this.selLoading = false;
          // this.studentList = res.data.data.records;
          res.data.data.forEach((index) => {
            this.studentList.push({
              value: index.studentId,
              label: index.name,
            });
          });
          if (this.studentList.length > 0) {
            this.studentVal = this.studentList[0].value;
          }

          // this.studentList.map((item, index) => {
          //   if (index == 0) {
          //     item.on = true;
          //   } else {
          //     item.on = false;
          //   }
          // });
          this.comBoxType = false;
          if (this.studentList.length > 0) {
            this.getExamListByTimeRange();
          }
        })
        .catch(() => {
          this.selLoading = false;
        });
    },
    getClass() {
      let data = { gradeId: this.optionYearVal, forks: 1 };
      this.optionsClass = [];
      this.studentList = [];
      this.studentVal = "";
      this.optionsClassVal = "";
      classlistbygrade(data).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            this.optionsClass.push({ value: item.classNum, label: item.name });
          });
          //如果是重点关注，就先存重点关注的学生
          this.studentData = res.data.data[0];
          // let arr = [{ value: "重点关注", label: "重点关注" }];
          // this.optionsClass = arr.concat(this.optionsClass);
          this.optionsClassVal = res.data.data[0].classNum;
          this.subjectlistbygradeandclassnum();
        }
      });
    },
    getOption() {
      getGradeList().then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            this.optionYear.push({
              value: item.id,
              label: item.name,
              level: item.level,
            });
            if (item.classCount && !this.optionYearVal) {
              this.optionYearVal = item.id;
            }
          });
          if (!this.optionYearVal) {
            this.optionYearVal = res.data.data[0].id;
          }
          this.getClass();
        }
      });
    },
    setMenu(i) {
      this.menuList.forEach((index) => {
        index.isClick = false;
      });
      this.menuList[i].isClick = true;
    },
  },
};
</script>
<style lang="scss">
.studentLearning {
  .morelist {
    // overflow-x: scroll;
    width: 120px;

    input:-ms-input-placeholder {
      color: #606266 !important;
    }
    input::placeholder {
      color: #606266;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
      .el-tag {
        color: #606266;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.studentLearning {
  width: 1220px;
  margin: 0 auto;
  .wrong-box {
    border: 1px solid #dddddd;
    padding: 80px 24px 24px;
    position: relative;
    margin: 24px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .right-box {
      border: 1px solid #dddddd;
      padding: 12px;
      width: 870px;
      .question-list {
        border: 1px solid #dddddd;
        width: 100%;
        margin-top: 20px;
        position: relative;
        .anaws-box {
          padding: 12px;
          margin: 12px 0;
          .anaws-box-title {
            color: $primary-color;
          }
        }

        .question-intro {
          display: flex;
          padding: 10px 30px;
          justify-content: space-between;
          align-items: center;
          background-color: #f5f5f5;

          span {
            margin-left: 20px;
            color: $primary-color;
            cursor: pointer;
          }
        }
        .question-box {
          margin: 24px 0;
          padding: 12px;
        }
        .quertion-num {
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: $primary-color;
          background-color: #f6f6f6;
        }
      }
      .right-box-top {
        display: flex;
        justify-content: space-between;
        background-color: #f6f6f6;

        .top-left {
          padding: 8px 16px;
        }
        .top-right {
          display: flex;
          .one-box {
            // &:first-child {
            color: $primary-color;
            // }
          }
          .right-list {
            padding: 8px 16px;
            border-left: 1px solid #dddddd;
            cursor: pointer;
            .el-icon--right {
              transform: rotateZ(180deg);
            }
            .el-icon-arrow-down:before {
              content: "";
              // ;
            }
          }
        }
      }
    }
    .left-box {
      width: 280px;
      border: 1px solid #dddddd;
      padding: 12px;
      .wrong-left-top {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0 12px;
        position: relative;
        margin-bottom: 24px;
        &::after {
          position: absolute;
          width: 1px;
          height: 80%;
          content: "";
          left: 50%;
          top: 10%;
          background-color: #dddddd;
        }
        // div {
        //   &:last-child {
        //     border-left: 1px solid #dddddd;
        //   }
        // // }

        p {
          font-size: 20px;
        }
        span {
          color: #dddddd;
        }
      }
    }
    .wrong-title {
      position: absolute;
      left: 0;
      top: 24px;
      padding-left: 24px;
      color: $primary-color;
      font-size: 20px;
      &::after {
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: $primary-color;
        content: "";
        left: 0;
        top: 0;
      }
    }
  }
  .student-box {
    border: 1px solid #dddddd;
    padding: 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .student-msg {
      .student-msg-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 800px;
        padding-right: 120px;
      }

      .line-box {
        text-align: center;
      }
      .student-intro {
        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          span {
            color: $primary-color;
          }
        }
        .title {
          font-size: 20px;
        }
      }
    }
    .student-list-box {
      width: 140px;
      height: 305px;
      overflow-y: scroll;
      .student-list {
        cursor: pointer;
      }
      div {
        text-align: center;
        label {
          margin-bottom: 0;
        }
        padding: 6px 12px;
        border-top: 1px solid #dddddd;
        &:last-child {
          border-bottom: 1px solid #dddddd;
        }
      }
      .on {
        color: $primary-color;
      }
    }
  }
  .knowledge-box {
    border: 1px solid #dddddd;
    /*padding: 24px;*/
    position: relative;
    .knowledge-top {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      div {
        padding: 6px 12px;
        border: 1px solid $primary-color;
        cursor: pointer;
      }
      .on {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
    .knowledge-title {
      position: absolute;
      left: 0;
      top: 24px;
      padding-left: 24px;
      color: $primary-color;
      font-size: 20px;
      &::after {
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: $primary-color;
        content: "";
        left: 0;
        top: 0;
      }
    }
  }
  .top-box {
    border: 1px solid #dddddd;
    padding: 12px;
    margin: 20px 0;
    p {
      margin-bottom: 6px;
    }
    .check-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .el-select {
        width: 180px;
      }
      .check-box-title {
        font-size: 18px;
        color: $primary-color;
        font-weight: bold;
      }
      .check-box-right {
        .btn-all {
          padding: 8px 18px;
        }
        .el-select {
          padding-right: 12px;
        }
      }
    }
  }
}
</style>
